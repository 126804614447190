import { useState } from "preact/hooks";
import help from "../assets/img/help.svg";
import menu from "../assets/img/menu.svg";
import soundOffIcon from "../assets/img/sound-off.svg";
import soundOnIcon from "../assets/img/sound-on.svg";
import logo from "../assets/img/winthesquare-web-logo.svg";
import {
  useUserProfile,
  useUserProfileUpdate
} from "../hooks/UserProfileContext";
import Drawer from "./Drawer";
import HowToPlay from "./GameInstructions";
import Theme from "../theme";
export default () => {
  const { soundOn } = useUserProfile();
  const updateProfile = useUserProfileUpdate();
  const [showInstructions, setShowInstructions] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <div
      style={`background:${Theme.backgroundColor};border-bottom:solid 1px rgba(255,255,255,.15)`}
      class="  -mx-3  px-2 mb-3 justify-between text-dark align-middle flex items-center border-b-1 sticky"
    >
      {/* <button class="m-0 mr-1 font-black text-4xl">▤</button> */}
      {/* <h1 class="m-0 font-black text-2xl">
        <div style="color:#FAE360;background:#333; " class="inline-block px-1">
          WIN
        </div>
        <div
          style="border:solid 2px;height:32px;top:2px;position:relative;"
          class="inline-block px-1 "
        >
          THE
        </div>{" "}
        ⬛
      </h1> */}
      <a href="/">
        <img src={logo} alt="logo" style="height:32px;" />
      </a>
      <div class="flex align-middle">
        <button onClick={() => setShowInstructions(!showInstructions)}>
          <img src={help} alt="Menu" height={36} width={45} />
        </button>
        <button onClick={() => updateProfile({ soundOn: !soundOn })}>
          <img
            src={soundOn ? soundOnIcon : soundOffIcon}
            alt="Menu"
            height={36}
            width={45}
          />
        </button>
        <button onClick={() => setShowDrawer(!showDrawer)}>
          <img src={menu} alt="Menu" height={36} width={36} />
        </button>
      </div>
      {showInstructions && (
        <div
          data-popover
          id="popover-default"
          role="tooltip"
          class="absolute z-10 top-10 right-2  inline-block w-80 text-sm text-gray-500 transition-opacity duration-300 bg-white border
         border-gray-200 rounded-lg shadow-sm  dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
        >
          <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
            <h3 class="font-semibold text-gray-900 dark:text-white">
              How to play
            </h3>
          </div>
          <div class="px-3 py-2">
            <HowToPlay />
          </div>
          <div data-popper-arrow></div>
        </div>
      )}
      {showDrawer && <Drawer onDismiss={() => setShowDrawer(false)}></Drawer>}
    </div>
  );
};
