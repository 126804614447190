import { PlayerMap, Turn } from "../types";
import { drawRectangle } from "./canvas.utils";
import { getRandomPattern } from "./fill-patterns";

export const renderState = (
  state: Turn[],
  ctx: CanvasRenderingContext2D,
  canvasWidth: number,
  players: PlayerMap
) => {
  ctx.clearRect(0, 0, canvasWidth, canvasWidth);

  if (!state.length) return;

  state.forEach(({ rectangle, owner, status }: Turn) => {
    if (status !== "valid") return;
    let color = players[owner].color || "rgba(52,199,89,.8)";
    drawRectangle(ctx, canvasWidth, rectangle, color, getRandomPattern());
  });
};
