import { initializeApp } from "firebase/app";
// Import the functions you need from the SDKs you need
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { DEV_HOST, IS_DEV } from "../constants";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigOld = {
  apiKey: "AIzaSyBc4DrXyiOn_xijLbbdeZJ-u-7XW4vKKOk",
  authDomain: "square-sg.firebaseapp.com",
  projectId: "square-sg",
  storageBucket: "square-sg.appspot.com",
  messagingSenderId: "598936526058",
  appId: "1:598936526058:web:2934f5e6008798525d0b93",
  measurementId: "G-QC6G016TRN"
};

const firebaseConfig = {
  apiKey: "AIzaSyCKDJfpG9PMzdkekhIO-YhWtcVqmW3bw1E",
  authDomain: "winthesquare.firebaseapp.com",
  projectId: "winthesquare",
  storageBucket: "winthesquare.appspot.com",
  messagingSenderId: "204920954278",
  appId: "1:204920954278:web:709f0855ada8468ca24367",
  measurementId: "G-N4R5C9G5CR"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
//

const db = getFirestore(app);
const functions = getFunctions(app, "asia-south1");

if (IS_DEV) {
  connectFirestoreEmulator(db, DEV_HOST, 8080);
  connectFunctionsEmulator(functions, DEV_HOST, 5001);
}
export { app, functions };
export default db;
