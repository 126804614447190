import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import db, { functions } from "./connect";

export const getGame = async (id: string) => {
  const gameRef = doc(db, "games", id);
  const gameSnap = await getDoc(gameRef);
  if (gameSnap.exists()) {
    return gameSnap.data();
  }
  return false;
};

// export const createGame = async (id: string, player: Player) => {
//   //@todo add error handling
//   await setDoc(doc(db, "games", id), {
//     players: [player],
//     turns: [],
//     toPlay: player.id,
//     createdAt: serverTimestamp()
//   });
// };

// // write a function to add a player to the game
// export const addPlayer = async (id: string, player: Player) => {
//   const gameRef = doc(db, "games", id);
//   await updateDoc(gameRef, {
//     players: arrayUnion(player)
//   });
// };

const addTurn = httpsCallable(functions, "addTurn");

const updateMoveStatus = httpsCallable(functions, "updateMoveStatus");

const addPlayer = httpsCallable(functions, "addPlayer");
const updateGameColor = httpsCallable(functions, "updateGameColor");
const makeMatch = httpsCallable(functions, "makeMatch");
const markGameAsActive = httpsCallable(functions, "markGameAsActive");
export {
  addPlayer,
  addTurn,
  updateGameColor,
  updateMoveStatus,
  makeMatch,
  markGameAsActive
};

// export const gameConvertor = {
//   toFirestore: (game: any) => game,
//   fromFirestore: (snap: any): Game => {
//     // Takes options: any
//     const data = snap.data();
//     const { turns } = data;
//     const { width } = canvasState;
//     const normalizedTurns = convertTurnsToPixels(turns, width);
//     data.turns = normalizedTurns;
//     return data;
//   }
// };
