import InstructionsSVG from "../assets/img/instructions.svg";
export default () => {
  return (
    <>
      <p class={`mb-2`}>
        Draw a rectangle in the empty area. You won't know your opponent's last
        move.
      </p>
      <ul class="list-disc ml-6 mb-4">
        <li>
          If you draw inside your opponent's last rectangle, you claim their
          area.
        </li>
        <li>If you draw outside, your opponent can try to claim it.</li>
        <li>If there's an overlap, your turn is wasted.</li>
      </ul>
      <p class="  font-light text-lg mb-6">
        The first player to claim 48% of the area wins.
      </p>
      <img src={InstructionsSVG} style="width:100%;height:auto; "></img>
    </>
  );
};
