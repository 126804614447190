import { useEffect, useState } from "react";
import { addPlayer, getGame, makeMatch } from "../firebase/game.utils";
import { Game } from "../types";
import { getOpponentColor, getUserColor } from "../utils/game-utils";

type Props = {
  autoMatch?: boolean;
  id?: string;
  name?: string;
  uid: string;
};
export default ({
  autoMatch,
  id,
  name,
  uid
}: Props): { gameID: string; error: any } => {
  const [gameID, setGameID] = useState<string | undefined>(id);
  const [error, setError] = useState<string | undefined>(undefined);
  useEffect(() => {
    (async () => {
      //If not id, then return home
      if (!id && !autoMatch) {
        window.location = "/";
      }
      if (!name) {
        return;
      }

      // setName(displayName);

      if (autoMatch) {
        console.log("###", id);
        try {
          const { data } = await makeMatch({
            name,
            color: getUserColor()
          });

          setGameID(data.gameID);
          if (!id) {
            window.location = `${window.location.origin}/game/${data.gameID}`;
          }
        } catch (e) {
          console.log("##error", e);
        }

        return;
      } else {
        const { players, playerMap } = ((await getGame(id)) as Game) || {};

        // If current player is already in the game, then return
        if (players && players.includes(uid)) {
          return;
        }
        if (players && players.length > 1) {
          setError("Game is full");
          return;
        }

        if (name) {
          const opponentColor = getOpponentColor(playerMap);
          addPlayer({
            gameID: id,
            name,
            color: getUserColor(opponentColor)
          });
        }
      }
    })();
  }, [autoMatch, id, name, uid]);

  console.log(gameID, error);

  return { gameID, error };
};
