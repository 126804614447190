import { useEffect, useState } from "preact/hooks";

type Props = {
  children: Element | string;
  rand: number;
  type?: "error" | "success" | "info";
  onDismiss?: () => void;
};
const classMap = {
  error: "bg-red-300/80",
  success: "bg-green-300/80",
  info: "bg-amber-300/80"
};
export default ({ children, rand, type = "error", onDismiss }: Props) => {
  const [show, setShow] = useState<boolean>(true);
  useEffect(() => {
    setShow(true);
  }, [rand]);
  console.log("rand", rand);
  return (
    <>
      {show && (
        <div
          class={`fixed bottom-0 p-2
          w-full flex justify-between items-center left-0
          ${classMap[type]}`}
          style={`  animation: 300ms ease-out 0 1 slideInFromBottom;`}
        >
          {children}
          <button
            class=" p-2 -m-2  "
            onClick={() => {
              setShow(false);
              if (typeof onDismiss === "function") {
                onDismiss();
              }
            }}
          >
            &times;
          </button>
        </div>
      )}
    </>
  );
};
