import { createRef } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { DrawingState, Offset, Point, Rectangle, State, Turn } from "../types";
import { clearBoard, drawRectangleOutline } from "../utils/canvas.utils";
import { getOffset, normalizePoint } from "../utils/position-utils";
import {
  area,
  isInsideRectangle,
  moveToClosestEdge,
  normalizeRectangle
} from "../utils/rectangle";
import sanitizeEnd from "../utils/sanitizeEnd";
import Confirm from "./Confirm";
import Error from "../components/Error";
import GLOBALS from "../globals";
import { initializeAudio, playSound } from "../utils/sound.utils";
import { updateMoveStatus } from "../firebase/game.utils";

type Props = {
  turns: State;
  width: number;
  addRectangle: (rectangle: Rectangle) => void;
  disabled: boolean;
  gameID: string;
};
type ErrorMessage = {
  message: string;
  code?: string;
};
export default ({
  turns: unsafeTurns,
  width,
  addRectangle,
  disabled,
  gameID
}: Props) => {
  const turns = unsafeTurns.filter(({ status }) => status === "valid");
  const canvasRef = createRef();

  const [offset, setOffset] = useState<Offset>({ top: 0, left: 0 });
  const [error, setError] = useState<ErrorMessage | null>(null);
  const [canvasContext, setCanvasContext] =
    useState<CanvasRenderingContext2D | null>(null);

  const [drawingComplete, setDrawingComplete] = useState<boolean>(false);

  const drawingState = useRef<DrawingState>({
    start: null,
    end: null,
    drawingComplete: false,
    isMouseDown: false
  });

  const resetDrawingState = () => {
    drawingState.current.start = null;
    drawingState.current.end = null;
    drawingState.current.drawingComplete = false;
    setDrawingComplete(false);
    clearBoard(width);
  };
  // Initial setup
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const offset = getOffset(canvas);
    canvas.style.width = canvas.style.height = `${width}px`;

    const scale = window.devicePixelRatio;
    canvas.width = canvas.height = width * scale;
    context.scale(scale, scale);

    setOffset(offset);

    // End
    window.addEventListener("mouseup", handleEnd);
    window.addEventListener("touchend", handleEnd);
    window.addEventListener("touchcancel", handleEnd);
  }, [width]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    setCanvasContext(context);
  }, []);

  const handleConfirm = () => {
    const { start, end } = drawingState.current;
    if (!end || !start) {
      return;
    }
    const normalizedRectangle = normalizeRectangle({ tl: start, br: end });
    if (area(normalizedRectangle) < 0.0000390625) {
      playSound("error");

      setError({ message: "Please draw a larger rectangle." });
      return;
    }
    addRectangle(normalizedRectangle);
    resetDrawingState();
  };

  const handleReset = () => {
    resetDrawingState();
  };

  function handleStart(e: MouseEvent, turns: State, offSet: Offset): void;
  function handleStart(e: TouchEvent, turns: State, offSet: Offset): void;
  function handleStart(e: any, turns: State, offSets: any) {
    e.preventDefault();
    e.stopPropagation();
    if (!GLOBALS.audioInitialized) {
      initializeAudio();
    }
    if (disabled) {
      console.log("Not your turn  - drawing disabled  ");
      playSound("error");

      setError({ message: "Please wait your turn." });
      return;
    }
    const { pageX, pageY } =
      e.type === "touchstart" ? (e as TouchEvent).touches[0] : e;
    const point = [pageX, pageY] as Point;
    const normalized = normalizePoint(point, offSets, width);
    const inside = turns.find((turn: Turn) =>
      isInsideRectangle(turn.rectangle, normalized)
    );
    resetDrawingState();
    if (inside) {
      drawingState.current.start = moveToClosestEdge(
        inside.rectangle,
        normalized
      );
    } else {
      drawingState.current.start = normalized;
    }
    setDrawingComplete(false);
    updateMoveStatus({ gameID, status: "started" });
  }

  function handleMove(
    e: MouseEvent,
    offset: Offset,
    ctx: CanvasRenderingContext2D
  ): void;
  function handleMove(
    e: TouchEvent,
    offset: Offset,
    ctx: CanvasRenderingContext2D
  ): void;

  function handleMove(e: any, offset: Offset, ctx: CanvasRenderingContext2D) {
    const { start, drawingComplete } = drawingState.current;
    if (!start || drawingComplete) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    let { pageX, pageY } =
      e.type === "touchmove" ? (e as TouchEvent).touches[0] : e;

    // document.getElementById("debugger").innerHTML = `<pre>${JSON.stringify({
    //   point: [pageX, pageY],
    //   offset,
    //   width
    // })}</pre>`;
    const end = normalizePoint(
      [Math.max(pageX, 0), Math.max(pageY, 0)],
      offset,
      width
    );
    // document.getElementById("debugger2").innerHTML = `<pre>${JSON.stringify(
    //   end
    // )}</pre>`;
    const sanitizedEnd = sanitizeEnd(turns, start, end);

    // document.getElementById("debugger3").innerHTML = `<pre>${JSON.stringify(
    //   sanitizedEnd
    // )}</pre>`;
    drawingState.current.end = sanitizedEnd;
    drawRectangleOutline(ctx, { tl: start, br: sanitizedEnd }, width);
  }
  const handleEnd = () => {
    const { start, end } = drawingState.current;
    if (!start || !end) {
      return;
    }
    drawingState.current.drawingComplete = true;
    setDrawingComplete(true);
    updateMoveStatus({ gameID, status: "completed" });
  };

  useEffect(() => {
    setError(null);
  }, [disabled]);
  return (
    <div
      // class="absolute"
      style={`grid-row-start: 1;
    grid-column-start: 1;align-items: start;position: relative;`}
    >
      <canvas
        id="board"
        ref={canvasRef}
        onMouseDown={(e) => handleStart(e, turns, offset)}
        onMouseMove={(e) =>
          handleMove(e, offset, canvasContext as CanvasRenderingContext2D)
        }
        onTouchStart={(e) => handleStart(e, turns, offset)}
        onTouchMove={(e) =>
          handleMove(e, offset, canvasContext as CanvasRenderingContext2D)
        }
        style="touch-action: none;"
      >
        An alternative text describing what your canvas displays.
      </canvas>

      {/* <div id="debugger" class="justify-center mt-4"></div>
      <div id="debugger2" class="justify-center mt-4"></div>
      <div id="debugger3" class="justify-center mt-4"></div> */}
      {drawingComplete && (
        <div
          id="confirm"
          class="absolute bottom-5 bg-white/40 left-0 right-0 z-90 justify-center p-1"
        >
          <Confirm confirm={handleConfirm} reset={handleReset}></Confirm>
        </div>
      )}
      {error && (
        <Error rand={Math.random()} onDismiss={() => setError(null)}>
          {error.message}
        </Error>
      )}
    </div>
  );
};
