import { DBTurn, Turn } from "../types";
import { playSound } from "./sound.utils";

export default (
  turns: DBTurn[] | Turn[],
  uid: string,
  toPlay: string,
  winner: string | undefined
) => {
  if (!turns || !turns.length) {
    if (toPlay === uid) {
      console.log("playSound yourTurn");
      playSound("yourTurn");
    }
    return;
  }
  if (winner === uid) {
    playSound("victory");
    return;
  }
  const lastTurn = turns[turns.length - 1];
  const { status, owner } = lastTurn;
  if (toPlay === uid) {
    // if opponent drew inside status === "inside" and you lost
    if (status === "inside" && owner !== uid) {
      console.log("playSound lost");
      playSound("lost");
      return;
    }

    // if opponent intersected status === "intersected" and you won
    if (status === "intersected" && owner !== uid) {
      console.log("playSound won");
      playSound("won");
      return;
    }

    // if opponent drew outside
    if (status === "valid" && owner === uid) {
      console.log("playSound won");
      playSound("won");
      return;
    }
    console.log("playSound yourTurn");
    playSound("yourTurn");
  } else {
    if (status === "inside" && owner === uid) {
      console.log("playSound won");
      playSound("won");
    }
    if (status === "intersected" && owner === uid) {
      console.log("playSound lost");
      playSound("lost");
    }
  }
};
