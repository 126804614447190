import { Point, Rectangle } from "../types";

const height = (rectangle: Rectangle): number => {
  const { tl, br } = rectangle;
  return Math.abs(br[1] - tl[1]);
};
const width = (rectangle: Rectangle): number => {
  const { tl, br } = rectangle;
  return Math.abs(br[0] - tl[0]);
};

/**
 * Normalizes a rectangle to ensure the corners are top_left and bottom_right
 *
 * @param {Rectangle} rectangle
 * @returns {Rectangle}
 */
const normalizeRectangle = (rectangle: Rectangle): Rectangle => {
  const { tl, br } = rectangle;

  return {
    tl: [Math.min(tl[0], br[0]), Math.min(tl[1], br[1])],
    br: [Math.max(tl[0], br[0]), Math.max(tl[1], br[1])]
  };
};

const intersect = (r1: Rectangle, r2: Rectangle, normalize = false) => {
  const { tl: tl1, br: br1 } = normalize ? normalizeRectangle(r1) : r1;
  const { tl: tl2, br: br2 } = normalize ? normalizeRectangle(r2) : r2;

  // Adjacent
  if (tl2[0] >= br1[0] || br2[0] <= tl1[0]) {
    return false;
  }
  // Vertical
  if (br1[1] <= tl2[1] || br2[1] <= tl1[1]) {
    return false;
  }
  return true;
};

const area = (rectangle: Rectangle) => {
  return width(rectangle) * height(rectangle);
};

const perimeter = (rectangle: Rectangle) => {
  return 2 * (width(rectangle) + height(rectangle));
};

/**
 *
 * @param {Rectangle} rectangle
 * @param {Point} point
 * @returns boolean
 */
const isInsideRectangle = (rectangle: Rectangle, point: Point) => {
  const { tl, br } = rectangle;
  return (
    tl[0] <= point[0] &&
    br[0] >= point[0] &&
    tl[1] <= point[1] &&
    br[1] >= point[1]
  );
};

const moveToClosestEdge = (rectangle: Rectangle, point: Point): Point => {
  const { tl, br } = rectangle;
  const [x, y] = point;
  const closestX = Math.abs(x - tl[0]) < Math.abs(x - br[0]) ? tl[0] : br[0];
  const closestY = Math.abs(y - tl[1]) < Math.abs(y - br[1]) ? tl[1] : br[1];
  return [closestX, closestY];
};
export {
  intersect,
  normalizeRectangle,
  area,
  height,
  width,
  perimeter,
  isInsideRectangle,
  moveToClosestEdge
};
