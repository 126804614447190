import {
  Point,
  Offset,
  Rectangle,
  DBTurn,
  DBRectangle,
  DBPoint
} from "../types";

export const getOffset = (elem: HTMLElement): Offset => {
  // crossbrowser version
  let box = elem.getBoundingClientRect();

  let body = document.body;
  let docEl = document.documentElement;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  let clientTop = docEl.clientTop || body.clientTop || 0;
  let clientLeft = docEl.clientLeft || body.clientLeft || 0;

  let top = box.top + scrollTop - clientTop;
  let left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
};

export const normalizePoint = <T extends Point | [number, number]>(
  point: T,
  offset: Offset,
  width: number
): T => {
  const [x, y] = point;
  const { top, left } = offset;
  const newX = Math.max(Math.min(x - left, width), 0);
  const newY = Math.max(Math.min(y - top, width), 0);
  return [newX / width, newY / width] as T;
};

// convert point in % to point in px
export const convertPointToPixels = (
  point: DBPoint,
  width: number,
  round: (x: number) => number
): Point => {
  const [x, y] = point;
  return [round(x * width), round(y * width)];
};
// convert point in px to point in %
export const convertPointToPercent = (point: Point, width: number): DBPoint => {
  const [x, y] = point;
  return [x / width, y / width];
};
// Convert rectangle using % coordinates to pixel coordinates
export const convertRectToPixels = (
  rect: DBRectangle,
  width: number
): Rectangle => {
  const { tl, br } = rect;
  return {
    tl: convertPointToPixels(tl, width, Math.floor),
    br: convertPointToPixels(br, width, Math.ceil)
  };
};

export const convertRectToPercent = (
  rect: Rectangle,
  width: number
): DBRectangle => {
  const { tl, br } = rect;
  return {
    tl: convertPointToPercent(tl, width),
    br: convertPointToPercent(br, width)
  };
};

export const convertTurnsToPixels = (turns: DBTurn[] = [], width: number) => {
  return turns.map(({ rectangle, owner, status }: DBTurn) => {
    return {
      rectangle: convertRectToPixels(rectangle, width),
      owner,
      status
    };
  });
};
