import db from "./firebase/connect";
import { render } from "preact";
import { App } from "./app";
import { signIn } from "./firebase/auth.utils";
import { initializeAudio } from "./utils/sound.utils";
//@todo why we need this?
console.log(db);
signIn();
render(<App />, document.getElementById("app")!);

document.addEventListener("click", initializeAudio, { once: true });
document.addEventListener("touchstart", initializeAudio, { once: true });
