// @ts-nocheck
/* tslint:disable */
import { colord } from "colord";
import { useEffect, useRef } from "preact/hooks";
import { getRandomColor, randomHSLA } from "../utils/colors";
const scale = window.devicePixelRatio;

const colour = "rgba(0, 0, 0, 1.3)";
const CANVAS_SIDE_LENGTH = 48;

const createDiagonalPinstripeCanvas = (flip = false) => {
  const patternCanvas = document.createElement("canvas") as HTMLCanvasElement;
  const pctx = patternCanvas.getContext("2d", {
    antialias: true
  }) as CanvasRenderingContext2D;

  const WIDTH = CANVAS_SIDE_LENGTH;
  const HEIGHT = CANVAS_SIDE_LENGTH;
  const DIVISIONS = 8;

  patternCanvas.width = patternCanvas.height = CANVAS_SIDE_LENGTH;

  if (flip) {
    pctx.translate(WIDTH, 0);
    pctx.rotate((90 * Math.PI) / 180);
  }
  pctx.fillStyle = colour;

  // Top line
  pctx.beginPath();
  pctx.moveTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), 0);
  pctx.lineTo(0, 0);
  pctx.lineTo(0, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Middle line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.lineTo(WIDTH * (1 / DIVISIONS), HEIGHT);
  pctx.lineTo(0, HEIGHT);
  pctx.lineTo(0, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), 0);
  pctx.lineTo(WIDTH, 0);
  pctx.lineTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  pctx.fill();

  // Bottom line
  pctx.beginPath();
  pctx.moveTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT);
  pctx.lineTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  pctx.fill();

  patternCanvas.width = patternCanvas.height = CANVAS_SIDE_LENGTH / 4;

  pctx.scale(0.25, 0.25);
  return patternCanvas;
};

/** Fills the whole area of a given htmlCanvasElement with a patternCanvas.
 * @param targetCanvas – the HTMLCanvasElement to fill into.
 * @param patternCanvas – a HTMLCanvasElement containing a pattern to fill with.
 */
function fillWithPattern(targetCanvas, patternCanvas) {
  const ctx = targetCanvas.getContext("2d", { antialias: false, depth: false });
  const width = targetCanvas.width;
  const height = targetCanvas.height;
  if (!width || !height)
    throw new Error("progressCanvas's width/height falsy.");

  ctx.fillStyle = ctx.createPattern(patternCanvas, "repeat");
  ctx.fillRect(0, 0, width, height);

  return targetCanvas;
}

// fillWithPattern(document.getElementById("targetCanvas"), createPinstripeCanvas());
// Parse HSL color string to hue, saturation, lightness, alpha
const parseColor = (color: string) => {
  const [h, s, l, a] = color.replace("hsl(", "").replace(")", "").split(",");
  // .map((v) => parseFloat(v));
  return { h, s, l, a };
};
const generateDivs = () => {
  const divs = [];
  for (let i = 0; i < 300; i++) {
    const color = randomHSLA();
    const { h, s, l, a } = parseColor(color);
    const block = (
      // <div style="padding:20px;background:#64b2f2">
      <div
        style={`background:${color};width:20%;height:200px;float:left;margin:10`}
      >
        Hue: {h}, <br></br>Saturation: {s}, <br></br>Lightness: {l},<br></br>
        Alpha: {a}
      </div>
      // </div>
    );
    divs.push(block);
  }
  return divs;
};

const generateColorPairs = () => {
  const divs = [];
  for (let i = 0; i < 300; i++) {
    const c1 = getRandomColor();
    const c2 = getRandomColor(c1);
    const { h, s, l, a } = parseColor(colord(c1).toHslString());
    const { h: h1, s: s1, l: l1, a: a1 } = parseColor(colord(c2).toHslString());
    const block = (
      <div style="display:flex;float:left;width:20%;padding:10px;box-sizing:border-box">
        <div
          style={`background:${c1};width:50%;height:200px;float:left;margin:10`}
        >
          {/* Hue: {h}, <br></br>Saturation: {s}, <br></br>Lightness: {l},<br></br>
          Alpha: {colord(c1).toHslString()} */}
          {h}
        </div>{" "}
        <div
          style={`background:${c2};width:50%;height:200px;float:left;margin:10`}
        >
          {/* Hue: {h1}, <br></br>Saturation: {s1}, <br></br>Lightness: {l1},
          <br></br>
          Alpha: {colord(c2).toHslString()} */}
          {h1}
        </div>
      </div>
    );
    divs.push(block);
  }
  return divs;
};

export default () => {
  const canvas = useRef();
  useEffect(() => {
    canvas.current.width = canvas.current.height = 300;

    fillWithPattern(canvas.current, createDiagonalPinstripeCanvas());
  });
  return <div ref={canvas}>{generateColorPairs()}</div>;
};
