import { colord } from "colord";

export const colors = [
  "#9560A8",

  //Pinks
  "#F294AD",
  "#F2B6C6",
  "#F2D7D0",
  "#F2C9DC",
  "#F2D0D0",
  "#F2D0D0",
  "#CCADB9",

  //Greens
  "#80B4BF",
  "#BBF2E2",
  "#038C73",
  "#21A698",
  "#5BB07B",

  //Orange - Yellow
  "#F2B05E",
  "#F26A4B",
  "#F2DE79",
  "#F2B077",
  "#D97941",

  //red
  "#FE4553",
  "#DA5362",
  "#F26B6B",
  "#F20544",
  "#E05F50",
  // Purple

  //Blue
  "#157EBF"
];
// Write a function that takes  start angle (0-360) and distance and returns a random angle outside the distance on either side without recursion
// ensure the number is between 0 and 360
export const getRandomHue = (excludeHue = 240, distance = 50) => {
  const min = 0;
  const max = 360 - 2 * distance;
  const random = Math.round(Math.random() * (max - min) + min);
  const hue = (random + excludeHue + distance) % 360;
  return hue;
};

export function randomHSLA(excludeHue = 240, distance = 50) {
  return `hsla(
    ${getRandomHue(excludeHue, distance)}, 
  ${Math.round((Math.random() * (90 - 65) + 65) * 100) / 100}%,  
  ${Math.round((Math.random() * (70 - 55) + 55) * 100) / 100}%,  
  1)`;
}

export const getRandomColor = (color = "#00f") => {
  const hue = colord(color).hue();
  return colord(randomHSLA(hue, 90)).toRgbString();
};

export const saturateColor = (color: string, saturation = 0.2) => {
  return colord(color).saturate(saturation).toRgbString();
};
