import { colord } from "colord";
import { createRef } from "preact";
import { useEffect } from "preact/hooks";
import { ANIMATION_SPEED } from "../constants";
import drawGrid from "../draw-grid";
import { PlayerMap, Rectangle, State } from "../types";
import { getPlayerColor } from "../utils";
import animateRectangle, { drawRectangle } from "../utils/canvas.utils";
import { renderState } from "../utils/draw-state";
import {
  getRandomPattern,
  resetPatternRequestCount
} from "../utils/fill-patterns";

type Props = {
  turns: State;
  width: number;
  playerMap: PlayerMap;
  unsettledTurn: Rectangle | null | undefined;
  currentUserId: string;
  color?: string;
  // state: Turn | null;
};
export default ({
  turns,
  width,
  playerMap,
  unsettledTurn,
  currentUserId,
  color
}: // state
Props) => {
  const gridRef = createRef();
  const stateRef = createRef();
  const animateRef = createRef();
  const unsettledTurnRef = createRef();

  //@todo get last unsettledTurn from DB on reload once

  // Init and scale all canvases
  useEffect(() => {
    const scale = window.devicePixelRatio;

    const canvas = stateRef.current;
    canvas.style.width = canvas.style.height = `${width}px`;
    const stateContext = canvas.getContext("2d");
    canvas.width = canvas.height = width * scale;
    stateContext.scale(scale, scale);

    const grid = gridRef.current;
    grid.style.width = grid.style.height = `${width}px`;
    const gridContext = grid.getContext("2d");
    grid.width = grid.height = width * scale;
    gridContext.scale(scale, scale);

    const animate = animateRef.current;
    animate.style.width = animate.style.height = `${width}px`;
    const animateContext = animate.getContext("2d");
    animate.width = animate.height = width * scale;
    animateContext.scale(scale, scale);

    const unsettledTurnCanvas = unsettledTurnRef.current;
    unsettledTurnCanvas.style.width =
      unsettledTurnCanvas.style.height = `${width}px`;
    const unsettledTurnContext = unsettledTurnCanvas.getContext("2d");
    unsettledTurnCanvas.width = unsettledTurnCanvas.height = width * scale;
    unsettledTurnContext.scale(scale, scale);

    drawGrid(gridContext, width, width);
  }, [width]);

  // Render state
  useEffect(() => {
    if (!turns.length) return;
    resetPatternRequestCount();
    const stateCanvas = stateRef.current;
    const stateContext = stateCanvas.getContext("2d");

    const turnsToSkip = turns[turns.length - 1].status === "valid" ? 1 : 2;
    renderState(
      turns.slice(0, turnsToSkip * -1),
      stateContext,
      width,
      playerMap
    );
  }, [turns.length]);

  // Render state
  useEffect(() => {
    if (!turns.length) return;
    resetPatternRequestCount();
    const stateCanvas = stateRef.current;
    const stateContext = stateCanvas.getContext("2d");

    renderState(turns, stateContext, width, playerMap);
  }, [width, color]);

  // Animate turns
  useEffect(() => {
    if (!turns.length) return;
    const canvas = animateRef.current;
    const animateContext = canvas.getContext("2d");
    animateContext.clearRect(0, 0, width, width);

    const lastTurn = turns[turns.length - 1];
    const turnsToAnimate = lastTurn.status === "valid" ? 1 : 2;

    const penultimateTurn =
      turnsToAnimate === 2 ? turns[turns.length - 2] : undefined;

    // TODO, we can animate if we control the order of animation for intersected and inside

    if (unsettledTurn && lastTurn.status !== "intersected") {
      drawRectangle(
        animateContext,
        width,
        unsettledTurn,
        "rgba(255,255,255,.3)"
      );
    }

    // animate penultimate turn
    if (penultimateTurn) {
      const { rectangle, owner } = penultimateTurn;
      animateRectangle(
        animateContext,
        width,
        rectangle,
        getPlayerColor(owner, playerMap),
        getRandomPattern()
      );
    }

    // animate last turn
    const { rectangle, owner, status } = lastTurn;
    let color = getPlayerColor(lastTurn.owner, playerMap);

    // Not animating last turn as it clears the intersected rectangle.
    // If we animate without clearing, it will draw over itself killing opacity
    if (status === "intersected") {
      color = colord(color).alpha(0.5).toRgbString();
      setTimeout(() => {
        drawRectangle(
          animateContext,
          width,
          rectangle,
          color,
          undefined,
          false
        );
      }, ANIMATION_SPEED * 19);

      return;
    }
    //If it its inside, we wait for the previous rectangle to be drawn
    // else it will clear it
    if (status === "inside") {
      color = owner === currentUserId ? `rgb(52,199,89)` : `rgb(255,59,48)`;
      setTimeout(() => {
        animateRectangle(animateContext, width, rectangle, color);
      }, ANIMATION_SPEED * 18);
      return;
    }
    animateRectangle(
      animateContext,
      width,
      rectangle,
      color,
      getRandomPattern()
    );
  }, [turns.length]);

  // Separated out as we are unable to clear canvas on invalid turns when drawing on animate canvas
  // We are drawing unsettled turn on animate canvas before animation to make it look like we are drawing over the turn
  // For this we had to delay resetting undettled turn in game / snapshot subscription
  useEffect(() => {
    const unsettledTurnCanvas = unsettledTurnRef.current;
    const unsettledTurnContext = unsettledTurnCanvas.getContext("2d");
    unsettledTurnContext.clearRect(0, 0, width, width);

    if (!unsettledTurn) {
      return;
    }
    drawRectangle(
      unsettledTurnContext,
      width,
      unsettledTurn,
      "rgba(255,255,255,.6)"
    );
  }, [unsettledTurn, width]);
  return (
    <div
      style={`grid-row-start: 1;
    grid-column-start: 1;align-items: start`}
    >
      <canvas id="grid" ref={gridRef} class="  absolute left-0">
        An alternative text describing what your canvas displays.
      </canvas>
      <canvas id="state" ref={stateRef} class=" absolute  left-0">
        An alternative text describing what your canvas displays.
      </canvas>
      <canvas id="unsettled" ref={unsettledTurnRef} class="  absolute left-0">
        An alternative text describing what your canvas displays.
      </canvas>
      <canvas id="animate" ref={animateRef} class="  absolute left-0">
        An alternative text describing what your canvas displays.
      </canvas>
    </div>
  );
};
