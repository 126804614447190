import { v4 as uuidv4 } from "uuid";
import appStoreBadge from "../assets/img/appstore-badge.svg";
import playStoreBadge from "../assets/img/playstore-badge.png";
export default () => {
  const id = localStorage.getItem("game") || uuidv4();
  return (
    <div
      class="text-center"
      style="display:flex;flex:1;flex-direction:column;flex-grow:1;
      justify-content:center;align-items:center;min-height:85vh"
    >
      <div>
        <h1 class="text-5xl font-bold">Win the Square</h1>
        <h2 class="font-light text-lg">
          Draw rectangles to conquer the square
        </h2>
        <div class="flex flex-col align-middle sm:flex-row sm:justify-center m-auto my-4 items-center">
          <a
            href={`/game/-1`}
            class="sm:mr-3  p-2  mb-4  w-32  home-btn rounded-2xl"
          >
            Start Game
          </a>
          <a
            href={`/game/${id}`}
            class="sm:mr-3  p-2  mb-4  w-32  home-btn rounded-2xl"
          >
            Play a friend
          </a>
          <a
            href="/instructions"
            class="sm:mr-3 p-2 w-32 mb-4  home-btn rounded-2xl"
          >
            How to play
          </a>

          {/* <a
            href={`/game/${id}`}
            class="sm:mr-3 p-2 w-32  mb-4  home-btn rounded-2xl"
          >
            Login
          </a> */}
        </div>

        <div class="badges-title">
          <p>Use the app for a better experience</p>
        </div>
        <div class="badges">
          <a href="https://apps.apple.com/us/app/winthesquare/id6468362074">
            <img src={appStoreBadge}></img>
          </a>
          <a href="https://play.google.com/store/apps/details?id=app.winthesquare">
            <img src={playStoreBadge}></img>
          </a>
        </div>
      </div>
    </div>
  );
};
