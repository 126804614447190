import { v4 as uuidv4 } from "uuid";
import HowToPlay from "../components/GameInstructions";
export default () => (
  <div class="    flex items-center justify-start " style="height:80%;">
    <div>
      <h1 class="font-black text-5xl">How to play</h1>
      <h2 class="font-light text-xl mb-4">
        Draw rectangles to conquer the square
      </h2>
      <div class=" font-normal  mb-8">
        <HowToPlay />
      </div>
      <div class="clearboth"></div>
      <div class={`mt-13 flex  text-center justify-between`}>
        <a href="/" class="p-2 w-24 block   bg-slate-200 rounded-2xl">
          ❮ Back
        </a>
        <a
          href={`/game/${uuidv4()}`}
          class=" p-2     w-24 bg-sky-300 rounded-2xl"
        >
          Play ❯
        </a>
      </div>
    </div>
  </div>
);
