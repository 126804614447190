import { HexColorPicker } from "react-colorful";
import useClickOutside from "../hooks/useClickOutside";
import { useCallback, useRef, useState } from "preact/hooks";

type Props = {
  color: string;
  onChange: (color: string) => void;
  className?: string;
};
const ColorPicker = ({ color, onChange, className }: Props) => {
  const popover = useRef<HTMLElement>(null);
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className={`picker ${className}`}>
      <div
        className="swatch w-5 h-5 rounded-full"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div className="popover absolute left-4 z-50" ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
