import { ComponentChildren, createContext } from "preact";
import { useContext, useState } from "preact/hooks";

const defaultUserProfile = {
  soundOn: true
};
type UserProfileContextData = {
  soundOn: boolean;
  color?: string;
};
const UserProfileContext =
  createContext<UserProfileContextData>(defaultUserProfile);
const UserProfileUpdateContext =
  createContext<
    (value: { [key: string]: string | number | boolean }) => void | undefined
  >(undefined);

export const useUserProfile = () => useContext(UserProfileContext);
export const useUserProfileUpdate = () => useContext(UserProfileUpdateContext);

export const UserProfileProvider = ({
  children
}: {
  children: ComponentChildren;
}) => {
  const userProfile =
    JSON.parse(localStorage.getItem("userProfile") || "0") ||
    defaultUserProfile;
  const [profile, setProfile] = useState(userProfile);
  const updateProfile = (value: { [key: string]: string }) => {
    const newProfile = { ...profile, ...value };
    localStorage.setItem("userProfile", JSON.stringify(newProfile));
    setProfile(newProfile);
  };
  return (
    <UserProfileContext.Provider value={profile}>
      <UserProfileUpdateContext.Provider value={updateProfile}>
        {children}
      </UserProfileUpdateContext.Provider>
    </UserProfileContext.Provider>
  );
};

export const getUserProfile = (key?: string) => {
  const profile =
    JSON.parse(localStorage.getItem("userProfile") || "0") ||
    defaultUserProfile;
  if (!key) return profile;
  return profile[key];
};
