import { Offset, Point } from "./types";

export const getCanvasWidth = () => {
  const width = Math.min(window.innerWidth, window.innerHeight - 180, 640);
  const mod = width % 20;
  return width - mod;
};
const canvasWidth = getCanvasWidth();
type DrawingState = {
  start: Point | null;
  end: Point | null;
  isMouseDown: boolean;
  reset: () => void;
};
type canvasState = {
  offset: Offset;
  width: number;
};
const drawingState: DrawingState = {
  start: null,
  end: null,
  isMouseDown: false,

  reset: function () {
    this.start = null;
    this.end = null;
    this.isMouseDown = false;
  }
};

const canvasState = {
  offset: { top: 0, left: 0 },
  width: canvasWidth
};
window.addEventListener("resize", () => {
  console.log("resized");
  canvasState.width = getCanvasWidth();
});
export { drawingState, canvasState };
