import { colord } from "colord";

export default (context: CanvasRenderingContext2D, bw = 600, bh = 600) => {
  const p = 0;

  for (let x = 0; x <= bw; x += 20) {
    context.moveTo(0.5 + x + p, p);
    context.lineTo(0.5 + x + p, bh + p);
  }

  for (let x = 0; x <= bh; x += 20) {
    context.moveTo(p, 0.5 + x + p);
    context.lineTo(bw + p, 0.5 + x + p);
  }
  // context.beginPath();

  context.strokeStyle = colord("#D9B26A").alpha(0.3).toHex();
  context.stroke();
};
