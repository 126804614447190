import { useAuthState } from "react-firebase-hooks/auth";
// import { auth } from "../firebase/auth.utils";
import { auth } from "../firebase/auth.utils";
import Game from "./Game";

export default ({ id }: { id: string }) => {
  const [user] = useAuthState(auth);
  let autoMatch = false;
  if (!user) {
    return <h1>Loading</h1>;
  }
  if (id === "-1") {
    autoMatch = true;
    id = "";
  }
  return <>{user && <Game id={id} autoMatch={true} />}</>;
};
