import {colord, extend} from 'colord';
import a11yPlugin from 'colord/plugins/a11y';
import {getCurrentUser} from '../firebase/auth.utils';
import {Game, Player, PlayerMap} from '../types';
import {getRandomColor} from './colors';
extend([a11yPlugin]);

export const getPlayerColorMap = (players: Player[]) => {
  if (!players || !players.length) {
    return {};
  }
  return {
    [players[0].id]: players[0].color || 'rgba(52,199,89,.8)',
    [players[1].id]: players[1].color || 'rgba(255,100,130,.8)',
  };
};

export const getPlayerColor = (
  playerId: string,
  playerMap: PlayerMap,
  opacity?: number,
) => {
  const color = playerMap[playerId].color || '#333';
  if (opacity) {
    return colord(color).alpha(opacity).toRgbString();
  }
  return color;
};

export const getPlayer = (players: PlayerMap) => {
  const playerId = getCurrentUser()?.uid;
  if (!playerId) {
    return null;
  }
  return players[playerId];
};
export const getOpponent = (players: PlayerMap): Player | null => {
  const playerId = getCurrentUser()?.uid;
  const opponent = Object.keys(players).find(p => p !== playerId);
  if (!opponent) {
    return null;
  }
  return players[opponent];
};
export const getOpponentName = (players: PlayerMap) => {
  const playerId = getCurrentUser()?.uid;

  const opponent = Object.keys(players).find(p => p !== playerId);
  if (!opponent) {
    return 'opponent';
  }
  return players[opponent].name;
};

export const getOpponentColor = (players: PlayerMap) => {
  if (!players) {
    return;
  }
  const playerId = getCurrentUser()?.uid;
  if (!playerId) {
    console.warn('No user logged in');
    return;
  }
  const playerIds = Object.keys(players);
  if (playerIds.length < 2) {
    return '#FF4858';
  }
  const opponent = Object.keys(players).find(p => p !== playerId);

  if (!opponent) {
    return;
  }
  return players[opponent].color;
};
export const getOpponentID = (players: PlayerMap) => {
  const playerId = getCurrentUser()?.uid;

  const opponent = Object.keys(players).find(p => p !== playerId);
  if (!opponent) {
    return 'opponent';
  }
  return opponent;
};

export const isTurn = (game: Game | undefined) => {
  if (!game) return false;
  if (game.winner) return false;
  const {toPlay, players} = game;
  if (players.length < 2) return false;

  const uid = getCurrentUser()?.uid;
  return toPlay === uid;
};

export const getUserColor = (color?: string | undefined) => {
  // const userColor = getUserProfile('color');
  const userColor = false;
  if (userColor) {
    const contrast = colord(userColor).contrast(color);
    if (contrast > 5) return userColor;
  }
  return getRandomColor(color);
};
