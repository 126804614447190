import { canvasState } from "../drawing-state";
import { Point, Rectangle, State } from "../types";
import { intersect, normalizeRectangle } from "./rectangle";

/**
 * Takes the list of rectangles and returns the first intersecting rectangle
 * @param state
 * @param rectangle
 */
const findIntersection = (
  state: State,
  predicate: Rectangle
): Rectangle | false => {
  predicate = normalizeRectangle(predicate);
  for (let i = 0; i < state.length; i++) {
    const result = intersect(state[i].rectangle, predicate);
    if (result) {
      return state[i].rectangle;
    }
  }
  return false;
};

/**
 * Takes a list of turns, start and end points of a rectangle and adjusts the end so that there are no overlaps.
 * @param {State} state
 * @param {Point} start
 * @param {Point} end
 * @returns {Point}
 */
export default (state: any, start: Point, end: Point) => {
  let result = findIntersection(state, { tl: start, br: end });
  const goingRight = start[0] < end[0];
  const goingDown = start[1] < end[1];
  let count = 0;
  while (result && count < state.length) {
    const { tl, br } = result;
    let xArea = 0;
    let yArea = 0;

    if (goingRight) {
      //console.log("🡢 Going right");
      if (tl[0] < end[0] && tl[0] > start[0]) {
        xArea = Math.abs((start[0] - tl[0]) * (start[1] - end[1]));
        //console.log("🡢 xArea", xArea);
      }
    } else {
      //console.log("🡠 Going left");

      if (br[0] > end[0] && br[0] < start[0]) {
        xArea = Math.abs((start[0] - br[0]) * (start[1] - end[1]));
        //console.log("🡠 xArea", xArea);
      }
    }

    if (goingDown) {
      //console.log("🡣 Going down", tl[1], end[1]);
      if (tl[1] < end[1] && tl[1] > start[1]) {
        yArea = Math.abs((start[0] - end[0]) * (start[1] - tl[1]));
        //console.log("🡣 yArea", yArea);
      }
    } else {
      //console.log("Going up");
      if (br[1] > end[1] && br[1] < start[1]) {
        yArea = Math.abs((start[0] - end[0]) * (start[1] - br[1]));
        //console.log("🡡 yArea", yArea);
      }
    }

    if (xArea > yArea) {
      // //console.log("xArea is more");
      end[0] = goingRight ? tl[0] : br[0];
    } else {
      end[1] = goingDown ? tl[1] : br[1];
    }
    result = findIntersection(state, { tl: start, br: end });
    // //console.log("Intersects with", result);
    count++;
  }
  // //console.log(count, end);
  if (end[0] > canvasState.width) {
    end[0] = canvasState.width;
  }
  if (end[0] < 0) {
    end[0] = 0;
  }
  if (end[1] > canvasState.width) {
    end[1] = canvasState.width;
  }
  if (end[1] < 0) {
    end[1] = 0;
  }
  return end;
};
