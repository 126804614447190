import { colord } from "colord";

const opulence = {
  backgroundColor: "#010326",
  color: "#D9B26A",
  tabActiveColor: "#F2C46D",
  tabInactiveColor: "#59584C",
  gridStroke: "#D9B26A",
  gridBG: "#010326",
  light1: "#fff",
  light2: "#FFF",
  borderColor: colord("#D9B26A").alpha(0.3).toHex()
};

export default opulence;
