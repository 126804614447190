//add types for the below function

export default ({ confirm: handleConfirm, reset: handleReset }: any) => {
  return (
    <div class="flex justify-center ">
      <button
        class="rounded mr-2  bg-transparent text-red-500 p-1 px-2
        "
        onClick={handleReset}
      >
        Reset
      </button>
      <button
        class="rounded bg-green-500 text-white p-1 px-2 font-bold"
        onClick={handleConfirm}
      >
        ✓ Confirm
      </button>
    </div>
  );
};
