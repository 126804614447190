import { createRef } from "preact";
import useClickOutside from "../hooks/useClickOutside";
import ProfileUpdateForm from "./ProfileUpdateForm";
import { logOut } from "../firebase/auth.utils";
type Props = { onDismiss: () => void };

export default ({ onDismiss }: Props) => {
  const drawerRef = createRef();
  useClickOutside(drawerRef, () => {
    onDismiss();
  });
  const handleLogout = async () => {
    await logOut();
    window.location = "/";
  };
  return (
    <div
      id="drawer-right-example"
      class="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform   bg-white w-80 dark:bg-gray-800"
      aria-labelledby="drawer-right-label"
      ref={drawerRef}
    >
      <h5
        id="drawer-right-label"
        class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
      >
        Profile & Navigation
      </h5>
      <button
        type="button"
        data-drawer-hide="drawer-right-example"
        aria-controls="drawer-right-example"
        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        onClick={() => onDismiss()}
      >
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span class="sr-only">Close menu</span>
      </button>
      <div>
        <ProfileUpdateForm></ProfileUpdateForm>
        <ul
          class="w-full text-sm font-medium text-gray-900 bg-white
         dark:bg-gray-800 dark:border-gray-600 dark:text-white"
        >
          <li class="w-full   py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600">
            FAQs
          </li>
          <li class="w-full   py-2 border-b border-gray-200 dark:border-gray-600">
            Feedback / Contact
          </li>
          <li
            class="w-full   py-2 border-b border-gray-200 dark:border-gray-600"
            onClick={handleLogout}
          >
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
};
