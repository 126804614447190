import { colord } from "colord";
import { Game } from "../types";

type Props = { game: Game; currentPlayerID: string };
export default ({ game, currentPlayerID }: Props) => {
  const { players, areas: areaMap, playerMap } = game;
  if (players.length !== 2) return null;

  const opponentID = players.find((p) => p !== currentPlayerID) || "";

  const currentPlayer = playerMap[currentPlayerID];
  const opponent = opponentID
    ? playerMap[opponentID]
    : { name: "Waiting...", color: "#CCC" };

  const currentPlayerPercent =
    areaMap && areaMap[currentPlayerID]
      ? Math.round(areaMap[currentPlayerID] * 10000) / 100
      : 0;

  const opponentPercent =
    areaMap && areaMap[opponentID]
      ? Math.round(areaMap[opponentID] * 10000) / 100
      : 0;

  return (
    <div id="game-progress" class="stripped-bg ">
      <div
        style={`width:${currentPlayerPercent}%;background:${colord(
          currentPlayer.color
        )
          .alpha(0.8)
          .toRgbString()}`}
      >
        &nbsp;{currentPlayer.name}{" "}
        {currentPlayerPercent ? `${currentPlayerPercent}%` : ""}
      </div>

      <div
        style="width:4%;"
        class={`bg-blue-900/80 absolute left-0 right-0 m-auto`}
      >
        &nbsp;
      </div>
      <div style={`width:${opponentPercent || 10}%`}>
        <div
          style={`text-align:right;background:${colord(opponent.color)
            .alpha(0.8)
            .toRgbString()};`}
        >
          {opponentPercent ? `${opponentPercent}%` : "0%"}&nbsp;
          {opponent.name}&nbsp;
        </div>
      </div>

      <div
        style={` text-align:center;display:inline-block;
        position:absolute;margin:auto;left:-1px;right:0;top:17px;color:black;font-weight:lighter`}
      >
        &#8593;
        <br /> First to touch this will win
      </div>
    </div>
  );
};
