import { useEffect, useState } from "preact/hooks";
import { getCanvasWidth } from "../drawing-state";

export default () => {
  const [width, setWidth] = useState(getCanvasWidth());
  const handleResize = () => {
    const newWidth = getCanvasWidth();
    setWidth(newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return width;
};
