import { ANIMATION_SPEED } from "../constants";
import { Rectangle } from "../types";
import { getRandomPattern } from "./fill-patterns";
import { convertRectToPixels } from "./position-utils";

export default (
  ctx: CanvasRenderingContext2D,
  canvasWidth: number,
  rect: Rectangle,
  color: string,
  patternCanvas?: HTMLCanvasElement
) => {
  const { tl, br } = convertRectToPixels(rect, canvasWidth);
  const [px, py] = tl;
  const width = br[0] - tl[0];
  const height = br[1] - tl[1];
  const multiplier = width / height;
  let x = 1;
  const speed = width / ANIMATION_SPEED;

  const animate = () => {
    ctx.clearRect(px, py, x, x / multiplier);
    ctx.fillStyle = color;
    ctx.fillRect(px, py, x, x / multiplier);

    if (patternCanvas) {
      const pattern = ctx.createPattern(
        patternCanvas,
        "repeat"
      ) as CanvasPattern;
      ctx.fillStyle = pattern;
      ctx.fillRect(px, py, x, x / multiplier);
    }
    if (x >= width) return;

    requestAnimationFrame(animate);

    x = x + speed > width ? width : x + speed;
  };
  animate();
};
export const drawRectangle = (
  ctx: CanvasRenderingContext2D,
  canvasWidth: number,
  rect: Rectangle,
  color: string,

  patternCanvas?: HTMLCanvasElement,
  clear = true
) => {
  const { tl, br } = convertRectToPixels(rect, canvasWidth);
  const [x, y] = tl;
  const width = br[0] - tl[0];
  const height = br[1] - tl[1];
  if (clear) ctx.clearRect(x, y, width, height);

  ctx.fillStyle = color;
  ctx.fillRect(x, y, width, height);

  if (patternCanvas) {
    const pattern = ctx.createPattern(
      getRandomPattern(),
      "repeat"
    ) as CanvasPattern;
    ctx.fillStyle = pattern;

    ctx.fillRect(x, y, width, height);
  }
};

export const clearBoard = (width: number) => {
  const canvas = <HTMLCanvasElement>document.getElementById("board");
  const ctx = <CanvasRenderingContext2D>canvas.getContext("2d");
  ctx.clearRect(0, 0, width, width);
};

export const drawRectangleOutline = (
  ctx: CanvasRenderingContext2D,
  rect: Rectangle,
  width: number
) => {
  const { tl, br } = convertRectToPixels(rect, width);
  ctx.clearRect(0, 0, width, width); //clear canvas

  ctx.beginPath();
  ctx.rect(tl[0], tl[1], ...[br[0] - tl[0], br[1] - tl[1]]);
  ctx.strokeStyle = "#333";
  ctx.setLineDash([4, 4]);

  ctx.lineWidth = 1;
  ctx.stroke();
};
