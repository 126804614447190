import { useState } from "preact/hooks";
import { Player } from "../types";
import GameInstructions from "../components/GameInstructions";
import ColorPicker from "../components/ColorPicker";
import { getRandomColor } from "../utils/colors";

type Props = {
  onSubmit: (obj: { name: string; color: string }) => void;
  invitedBy: Player | null;
};
export default ({ onSubmit, invitedBy }: Props) => {
  const [name, setName] = useState("");
  const [color, setColor] = useState(getRandomColor(invitedBy?.color));

  const handleSubmit = () => {
    // updateCurrentUser({ displayName: name });
    onSubmit({ name, color });
  };
  console.log(invitedBy);
  return (
    <>
      <div class="relative flex justify-center ">
        <div
          class="fixed inset-0 z-10 overflow-y-auto bg-neutral-600/90 h-screen"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex items-center justify-center min-h-screen px- pt-4 pb-20 
       text-center sm:block sm:p-0"
          >
            <div
              class="inline-block px-4 pt-5 pb-4 overflow-hidden              text-left align-bottom transition-all transform bg-white rounded-lg 
            shadow-xl rtl:text-right  sm:my-8 sm:align-middle w-full
             sm:max-w-sm sm:w-full sm:p-6 m-4"
              style="background:#010326"
            >
              <div>
                <div class="">
                  <h3
                    class="text-lg font-medium leading-6
"
                    id="modal-title"
                  >
                    Howdy {`${name ? name : "Guest"}`}!
                  </h3>
                  {invitedBy?.name && (
                    <p class="font-light mt-2">
                      <span
                        class="bg-green-600 px-1 text-dark-100 font-bold"
                        style={`background:${invitedBy.color}`}
                      >
                        {invitedBy.name}
                      </span>{" "}
                      invited you for a game
                    </p>
                  )}
                  <div class="flex-1   mt-4 md:mt-0">
                    {/* <label class="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                      Your Name
                    </label> */}
                    <input
                      type="text"
                      value={name}
                      onInput={(e) => {
                        setName((e.target as HTMLInputElement).value || "");
                      }}
                      placeholder="Enter your full name"
                      class="block w-full p-2 my-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg   focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <div class="flex my-4">
                      <label class="mr-2">Pick your color</label>
                      <ColorPicker
                        color={color}
                        onChange={(color) => setColor(color)}
                      ></ColorPicker>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-5 flex  justify-between items-center">
                <a href="/" class="text-sm text-blue-500 hover:underline">
                  {invitedBy ? "Decline" : "Cancel"}
                </a>

                {/* <div class="sm:flex sm:items-center "> */}
                <button
                  onClick={handleSubmit}
                  class="  px-4 py-2   text-sm font-medium tracking-wide text-white 
                    capitalize transition-colors duration-300 transform  w-28
                     bg-blue-600 rounded-md  hover:bg-blue-500 focus:outline-none focus:ring
                      focus:ring-blue-300 focus:ring-opacity-40
                    disabled:opacity-25"
                  disabled={!name || name.length < 3}
                >
                  Enter
                </button>
                {/* </div> */}
              </div>
              <div class=" font-normal  my-6  ">
                <h3
                  class="text-xl mb-2   -mx-6 px-6 font-medium"
                  style="background:#D9B26A;color:#010326"
                >
                  How to play
                </h3>
                <GameInstructions />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
