const colour = "rgba(255, 255, 255, .7)";
const scale = window.devicePixelRatio;

const patternRequestCount = { count: 0 };
function createDiagonalPinstripeCanvas(flip = false) {
  const patternCanvas = <HTMLCanvasElement>document.createElement("canvas");
  const ctx = <CanvasRenderingContext2D>(
    patternCanvas.getContext("2d", { antialias: false })
  );

  const CANVAS_SIDE_LENGTH = 9;
  const WIDTH = CANVAS_SIDE_LENGTH;
  const HEIGHT = CANVAS_SIDE_LENGTH;
  const DIVISIONS = 9;

  patternCanvas.width = patternCanvas.height = CANVAS_SIDE_LENGTH;
  // ctx.scale(scale, scale);

  if (flip) {
    ctx.translate(WIDTH, 0);
    ctx.rotate((90 * Math.PI) / 180);
  }
  ctx.fillStyle = colour;

  // Top line
  ctx.beginPath();
  ctx.moveTo(0, HEIGHT * (1 / DIVISIONS));
  ctx.lineTo(WIDTH * (1 / DIVISIONS), 0);
  ctx.lineTo(0, 0);
  ctx.lineTo(0, HEIGHT * (1 / DIVISIONS));
  ctx.fill();

  // Middle line
  ctx.beginPath();
  ctx.moveTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  ctx.lineTo(WIDTH * (1 / DIVISIONS), HEIGHT);
  ctx.lineTo(0, HEIGHT);
  ctx.lineTo(0, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  ctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), 0);
  ctx.lineTo(WIDTH, 0);
  ctx.lineTo(WIDTH, HEIGHT * (1 / DIVISIONS));
  ctx.fill();

  // Bottom line
  ctx.beginPath();
  ctx.moveTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  ctx.lineTo(WIDTH * ((DIVISIONS - 1) / DIVISIONS), HEIGHT);
  ctx.lineTo(WIDTH, HEIGHT);
  ctx.lineTo(WIDTH, HEIGHT * ((DIVISIONS - 1) / DIVISIONS));
  ctx.fill();
  ctx.scale(scale, scale);
  return patternCanvas;
}

function createAxisPinstripes(flip = false) {
  const patternCanvas = <HTMLCanvasElement>document.createElement("canvas");
  const ctx = <CanvasRenderingContext2D>(
    patternCanvas.getContext("2d", { antialias: true })
  );

  const CANVAS_SIDE_LENGTH = 12;
  const WIDTH = CANVAS_SIDE_LENGTH;
  const HEIGHT = CANVAS_SIDE_LENGTH;
  const DIVISIONS = 12;

  // patternCanvas.width = patternCanvas.height = CANVAS_SIDE_LENGTH * scale;
  // ctx.scale(scale, scale);

  patternCanvas.width = patternCanvas.height = CANVAS_SIDE_LENGTH;
  // ctx.scale(scale, scale);

  if (flip) {
    ctx.translate(WIDTH, 0);
    ctx.rotate(Math.PI / 2); // rotate 90 degrees
  }
  ctx.fillStyle = colour;

  // Top line
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(WIDTH, 0);
  ctx.lineTo(WIDTH, (1 / DIVISIONS) * HEIGHT);
  ctx.lineTo(0, (1 / DIVISIONS) * HEIGHT);
  ctx.lineTo(0, 0);
  ctx.fill();

  // Middle line
  ctx.beginPath();
  ctx.moveTo(0, HEIGHT / 2);
  ctx.lineTo(WIDTH, HEIGHT / 2);
  ctx.lineTo(WIDTH, HEIGHT / 2 + (1 * HEIGHT) / DIVISIONS);
  ctx.lineTo(0, HEIGHT / 2 + (1 * HEIGHT) / DIVISIONS);
  ctx.lineTo(0, HEIGHT / 2);

  ctx.fill();

  // // Bottom line
  // ctx.beginPath();
  // ctx.moveTo(0, HEIGHT - (0.5 * HEIGHT) / DIVISIONS);
  // ctx.lineTo(WIDTH, HEIGHT - (0.5 * HEIGHT) / DIVISIONS);
  // ctx.lineTo(WIDTH, HEIGHT);
  // ctx.lineTo(0, HEIGHT);
  // ctx.lineTo(0, HEIGHT - (0.5 * HEIGHT) / DIVISIONS);
  // ctx.fill();

  ctx.scale(scale, scale);
  return patternCanvas;
}

function createMeshPattern() {
  const patternCanvas = <HTMLCanvasElement>document.createElement("canvas");
  const ctx = <CanvasRenderingContext2D>(
    patternCanvas.getContext("2d", { antialias: true })
  );

  const CANVAS_SIDE_LENGTH = 12;
  const WIDTH = CANVAS_SIDE_LENGTH;
  const HEIGHT = CANVAS_SIDE_LENGTH;
  const DIVISIONS = 12;

  patternCanvas.width = patternCanvas.height = CANVAS_SIDE_LENGTH;
  // ctx.scale(scale, scale);
  ctx.fillStyle = colour;

  // // Horizontal line
  ctx.beginPath();
  ctx.moveTo(0, HEIGHT / 2 - (0.5 * HEIGHT) / DIVISIONS);
  ctx.lineTo(WIDTH, HEIGHT / 2 - (0.5 * HEIGHT) / DIVISIONS);
  ctx.lineTo(WIDTH, HEIGHT / 2 + (0.5 * HEIGHT) / DIVISIONS);
  ctx.lineTo(0, HEIGHT / 2 + (0.5 * HEIGHT) / DIVISIONS);
  ctx.lineTo(0, HEIGHT / 2 - (0.5 * HEIGHT) / DIVISIONS);
  ctx.fill();

  // Vertical line
  ctx.beginPath();
  ctx.moveTo(WIDTH / 2 - (0.5 * WIDTH) / DIVISIONS, 0);
  ctx.lineTo(WIDTH / 2 - (0.5 * WIDTH) / DIVISIONS, HEIGHT);
  ctx.lineTo(WIDTH / 2 + (0.5 * WIDTH) / DIVISIONS, HEIGHT);
  ctx.lineTo(WIDTH / 2 + (0.5 * WIDTH) / DIVISIONS, 0);
  ctx.lineTo(WIDTH / 2 - (0.5 * WIDTH) / DIVISIONS, 0);
  ctx.fill();

  ctx.scale(scale, scale);
  return patternCanvas;
}

const diagonal = createDiagonalPinstripeCanvas();
const diagonalFlipped = createDiagonalPinstripeCanvas(true);
const Horizontal = createAxisPinstripes();
const vertical = createAxisPinstripes(true);
const mesh = createMeshPattern();

const patterns = [diagonal, diagonalFlipped, Horizontal, vertical, mesh];

const getRandomPattern = () => {
  const { count } = patternRequestCount;
  patternRequestCount.count++;
  return patterns[count % patterns.length];
  return patterns[Math.floor(Math.random() * patterns.length)];
};
export const resetPatternRequestCount = () => {
  patternRequestCount.count = 0;
};
export {
  diagonal,
  diagonalFlipped,
  Horizontal,
  vertical,
  mesh,
  getRandomPattern
};
