import { Route, Router } from "preact-router";
import Canvas from "./components/Canvas";
import TopBar from "./components/TopBar";
import GameScreen from "./screens/GameScreen";
import Home from "./screens/Home";
import HowToPlay from "./screens/HowToPlay";
import blank from "./assets/sounds/blank.mp3";
import { UserProfileProvider } from "./hooks/UserProfileContext";

export function App() {
  return (
    <UserProfileProvider>
      <TopBar></TopBar>
      <Router>
        <Route path="/" component={Home} />
        <Route path="/instructions" component={HowToPlay} />
        {/* <About path="/about" /> */}
        <Route path="/game/:id" component={GameScreen} />
        <Route path="/canvas" component={Canvas} />
      </Router>
      <audio id="audio" src={blank}></audio>
    </UserProfileProvider>
  );
}
