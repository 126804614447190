import Ping from "../components/Ping";
import { getCurrentUser } from "../firebase/auth.utils";
import { Game, Player, PlayerMap } from "../types";
import { colord, extend } from "colord";
import { getRandomColor } from "./colors";
import { getUserProfile } from "../hooks/UserProfileContext";
import a11yPlugin from "colord/plugins/a11y";
extend([a11yPlugin]);

export const getPlayerColorMap = (players: Player[]) => {
  if (!players || !players.length) {
    return {};
  }
  return {
    [players[0].id]: players[0].color || "rgba(52,199,89,.8)",
    [players[1].id]: players[1].color || "rgba(255,100,130,.8)"
  };
};

export const getPlayerColor = (
  playerId: string,
  playerMap: PlayerMap,
  opacity?: number
) => {
  const color = playerMap[playerId].color || "#333";
  if (opacity) {
    return colord(color).alpha(opacity).toRgbString();
  }
  return color;
};

export const getOpponent = (players: PlayerMap): Player | null => {
  const playerId = getCurrentUser()?.uid;
  const opponent = Object.keys(players).find((p) => p !== playerId);
  if (!opponent) {
    return null;
  }
  return players[opponent];
};
export const getOpponentName = (players: PlayerMap) => {
  const playerId = getCurrentUser()?.uid;

  const opponent = Object.keys(players).find((p) => p !== playerId);
  if (!opponent) {
    return "opponent";
  }
  return players[opponent].name;
};
export const getOpponentID = (players: PlayerMap) => {
  const playerId = getCurrentUser()?.uid;

  const opponent = Object.keys(players).find((p) => p !== playerId);
  if (!opponent) {
    return "opponent";
  }
  return opponent;
};

export const isTurn = (game: Game | null) => {
  if (!game) return false;
  if (game.winner) return false;
  const { toPlay, players } = game;
  if (players.length < 2) return false;

  const uid = getCurrentUser()?.uid;
  return toPlay === uid;
};

export const getMessage = (game: Game | null) => {
  if (!game) return;
  if (game.winner) return;
  if (isTurn(game)) {
    return (
      <>
        <span class="mr-2">Your turn</span>
        <Ping></Ping>
      </>
    );
  }

  const { id: opponentID, name: opponentName } =
    getOpponent(game.playerMap) || {};
  if (!opponentID) return "Waiting for opponent to join...";
  const moveStatus = game.moveStatus[opponentID];
  if (!moveStatus) return `waiting for ${opponentName} to take their turn`;
  if (moveStatus === "started") {
    return (
      <div class="inline-flex justify-left items-start ">
        <div class="w-10 h-5 mr-1">
          <div class="move-status-rectangle rectangle-animation ">&nbsp;</div>
        </div>
        {opponentName} is drawing
      </div>
    );
  }
  if (moveStatus === "completed") {
    return (
      <div class="inline-flex justify-left items-start ">
        <div class="w-10 h-5 mr-1">
          <div class="move-status-rectangle">&nbsp;</div>
        </div>
        {opponentName} finished drawing, and admiring their art!!
      </div>
    );
  }
};

export const getUserColor = (color?: string | undefined) => {
  const userColor = getUserProfile("color");
  if (userColor) {
    const contrast = colord(userColor).contrast(color);
    if (contrast > 5) return userColor;
  }
  return getRandomColor(color);
};
