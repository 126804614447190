import {
  User as FirebaseUser,
  connectAuthEmulator,
  getAuth,
  signInAnonymously,
  signOut,
  updateProfile
} from "firebase/auth";
import { DEV_HOST, IS_DEV } from "../constants";

export const auth = getAuth();
if (IS_DEV) {
  connectAuthEmulator(auth, `http://${DEV_HOST}:9099`);
}
export const signIn = () => {
  signInAnonymously(auth)
    .then(() => {
      // Signed in..
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      // ...
    });
};

export const getCurrentUser = (): FirebaseUser | null => {
  return auth.currentUser;
};

export const updateCurrentUser = (user: { displayName: string }) => {
  if (!auth.currentUser) return;
  updateProfile(auth.currentUser, user);
};

export const logOut = async () => {
  const auth = getAuth();
  await signOut(auth);
};
