import yourTurn from "../assets/sounds/your-turn.mp3";
import won from "../assets/sounds/won.mp3";
import lost from "../assets/sounds/lost.mp3";
import error from "../assets/sounds/error.mp3";
import victory from "../assets/sounds/victory.mp3";

import GLOBALS from "../globals";
import { getUserProfile } from "../hooks/UserProfileContext";

export const initializeAudio = () => {
  console.log("Initializing audio element");
  const audio = <HTMLAudioElement>document.getElementById("audio");
  audio.play();
  GLOBALS.audioInitialized = true;
};

const soundMap = {
  yourTurn: yourTurn,
  won: won,
  lost: lost,
  error: error,
  victory: victory
};
// play sound
export const playSound = (sound: keyof typeof soundMap) => {
  if (!GLOBALS.audioInitialized) return;
  const soundOn = getUserProfile("soundOn");
  if (!soundOn) return;
  const audio = document.getElementById("audio") as HTMLAudioElement;
  audio.src = soundMap[sound];
  // console.log("playSound", sound);
  audio.play();
};
