import { useState } from "preact/hooks";
import { getCurrentUser, updateCurrentUser } from "../firebase/auth.utils";
import ColorPicker from "./ColorPicker";
import {
  useUserProfile,
  useUserProfileUpdate
} from "../hooks/UserProfileContext";
import { updateGameColor } from "../firebase/game.utils";
import debounce from "just-debounce-it";
const debouncedUpdateGameColor = debounce(updateGameColor, 1000);

//Checks window location to see if we are in a game
// and returns game id. If not in game, returns false.
const getGameId = () => {
  const path = window.location.pathname;
  const pathParts = path.split("/");
  if (pathParts[1] !== "game") {
    return false;
  }
  const gameId = pathParts[2];
  if (gameId) {
    return gameId;
  }
  return false;
};
export default () => {
  const user = getCurrentUser();
  const updateProfile = useUserProfileUpdate();
  const { color: currentColor } = useUserProfile();

  const [color, _setColor] = useState(currentColor || "red");
  const setColor = (color: string) => {
    updateProfile({ color });
    _setColor(color);
    const gameID = getGameId();
    if (gameID) {
      debouncedUpdateGameColor({ gameID, color });
    }
  };

  const [error, setError] = useState("");

  const updateUserName = (e: any) => {
    if (e.target.value.length > 20) {
      setError("Name must be less than 20 characters");
    }
    if (e.target.value.length < 3) {
      setError("Name must be at least 3 characters");
    }
    setError("");
    updateCurrentUser({ displayName: e.target.value });
  };

  return (
    <form action="#" class="mb-6">
      <div class="mb-6">
        <div class="mb-2">
          <label
            for="title"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Name
          </label>
          <input
            type="text"
            id="title"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 
   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder={user?.displayName || ""}
            required
            onBlur={updateUserName}
          />
          {error && <p class="text-red-500 text-xs italic mt-2">{error}</p>}
        </div>
        <div class="mb-2 flex   items-center">
          <label
            for="title"
            class="block  mr-4 text-sm font-medium text-gray-900 dark:text-white"
          >
            Default Game Color
          </label>
          <ColorPicker color={color} onChange={setColor} />
        </div>
      </div>
    </form>
  );
};
