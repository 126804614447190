import { doc, onSnapshot } from "firebase/firestore";
import debounce from "just-debounce-it";
import { useEffect, useRef, useState } from "preact/hooks";
import otterDance from "../assets/img/party_otter_dance.gif";
import ColorPicker from "../components/ColorPicker";
import DrawingCanvas from "../components/DrawingCanvas";
import Error from "../components/Error";
import GameProgress from "../components/GameProgress";
import StateCanvas from "../components/StateCanvas";
import Spinner from "../components/spinner";
import { ANIMATION_SPEED } from "../constants";
import { getCurrentUser, updateCurrentUser } from "../firebase/auth.utils";
import db from "../firebase/connect";
import { addPlayer, addTurn, updateGameColor } from "../firebase/game.utils";
import useCanvasWidth from "../hooks/useCanvasWidth";
import useSetupGame from "../hooks/useSetupGame";
import { DBGame, Game, Rectangle } from "../types";
import { getMessage, isTurn } from "../utils";
import notifySound from "../utils/notify-sound";
import { playSound } from "../utils/sound.utils";
import UserDetails from "./UserDetails";
const debouncedUpdateGameColor = debounce(updateGameColor, 1000);

export default ({
  id,
  autoMatch = false
}: {
  id: string;
  autoMatch: boolean;
}) => {
  const canvasWidth = useCanvasWidth();
  const [name, setName] = useState(getCurrentUser()?.displayName);
  const [game, _setGame] = useState<Game | null>(null);
  const [unsettledTurn, setUnsettledTurn] = useState<Rectangle | undefined>();
  const [isAddTurnLoading, setIsAddTurnLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const uid = getCurrentUser()?.uid || "";
  const gameRef = useRef(game);

  const setGame = (game: Game) => {
    gameRef.current = game;
    _setGame(game);
  };
  console.log("Here...");
  const { gameID, error: setupError } = useSetupGame({
    autoMatch,
    id,
    name,
    uid
  });

  // Subscribe to snapshots of the game
  useEffect(() => {
    if (!id) return;
    const unsubscribe = onSnapshot(doc(db, "games", id), (doc) => {
      const data = doc.data() as DBGame;
      if (!data) return;
      const game = gameRef.current;
      const previousTurnCount = game?.turns.length || 0;
      const previousToPlay = game?.toPlay;
      const { turns, toPlay, winner } = data;
      if (turns.length - previousTurnCount > 1) {
        setTimeout(() => setUnsettledTurn(undefined), 17 * ANIMATION_SPEED);
      }
      if (previousToPlay !== toPlay) {
        notifySound(turns, uid, toPlay, winner);
      }

      //@todo find a way to filter it at prehook level
      setGame({
        ...data
      });
    });
    return unsubscribe;
  }, [id]);

  const addRectangle = async (rectangle: Rectangle) => {
    setUnsettledTurn(rectangle);
    setIsAddTurnLoading(true);
    setError("");

    try {
      await addTurn({ gameID: id, rectangle });
    } catch (e: any) {
      setError(e.message);
      playSound("error");
      console.log(e);
      setUnsettledTurn(undefined);
    } finally {
      setIsAddTurnLoading(false);
    }
  };

  const handleUserSubmit = async ({
    name,
    color
  }: {
    name: string;
    color: string;
  }) => {
    const userId = getCurrentUser()?.uid;
    if (!userId) {
      return;
    }
    updateCurrentUser({ displayName: name });
    addPlayer({ gameID: id, name, color });
    setName(name);
  };

  const setColor = (color: string) => {
    debouncedUpdateGameColor({ gameID: id, color });
  };

  const playerColor = game?.playerMap[uid] ? game.playerMap[uid].color : "#333";
  return (
    <div style={`margin:0 -10px;width 100%;`}>
      <div class="px-2">
        {!name && (
          <UserDetails
            onSubmit={handleUserSubmit}
            invitedBy={
              game && game.players.length === 1
                ? game.playerMap[game.players[0]]
                : null
            }
          ></UserDetails>
        )}

        <div style="height:80px;overflow:auto;line-height:20px;font-size:14px">
          <div class="flex items-start mb-1">
            <div class="flex">
              Hello&nbsp;
              <ColorPicker
                color={playerColor}
                onChange={setColor}
                className="mr-1   "
              />
              {/* <span
                style={`color:${
                  colord(playerColor).isLight() ? "#333" : "#f0f1df"
                };background-color:${playerColor}`}
                class="font-bold px-1"
              > */}
              <span> {name}</span>
              {/* </span> */}
              ,&nbsp;
              {game && !isAddTurnLoading && game?.messages && (
                <span>{game.messages[uid]}</span>
              )}
            </div>
          </div>
          {isAddTurnLoading && (
            <div class="flex justify-start items-center              ">
              <Spinner />
              <span class="">Adding your turn</span>
            </div>
          )}
          {!isAddTurnLoading && getMessage(game)}
        </div>

        {game && game?.winner && game.winner === uid && (
          <div id="winner">
            Yayy!! You won <img src={otterDance} alt="otter dance" />
          </div>
        )}
        {game && game?.winner && game.winner !== uid && (
          <div id="winner">Tough luck! Next time 💪</div>
        )}
      </div>

      <div
        id="game"
        class="relative    m-auto pb-6"
        style={`width:${canvasWidth}px;display:grid; grid-template-columns: 1fr; 
        grid-auto-rows: minmax(min-content, max-content);`}
      >
        <StateCanvas
          turns={game ? game.turns : []}
          unsettledTurn={unsettledTurn}
          width={canvasWidth}
          currentUserId={uid}
          playerMap={game ? game.playerMap : {}}
          color={playerColor}
        ></StateCanvas>
        <DrawingCanvas
          turns={game ? game.turns : []}
          gameID={id}
          disabled={!isTurn(game) || Boolean(game?.winner)}
          width={canvasWidth}
          addRectangle={addRectangle}
        ></DrawingCanvas>
        {game && (
          <div
            class={`h6`}
            style={`position:absolute;width:${canvasWidth}px;top:${canvasWidth}px`}
          >
            <GameProgress game={game} currentPlayerID={uid}></GameProgress>
          </div>
        )}
        {/* <div class="mt-6 flex   items-center">
          <ColorPicker
            color={playerColor}
            onChange={setColor}
            className="mr-2"
          />
          <label
            for="title"
            class="block   text-sm font-medium text-gray-900 dark:text-white"
          >
            Default Game Color
          </label>
        </div> */}
      </div>
      {error && <Error rand={Math.random()}>{error}</Error>}
      {!autoMatch && game && game.players.length < 2 && (
        <Error type="info" onDismiss={() => setError("")}>
          <p>Share the page/link with your friend to play</p>
        </Error>
      )}
    </div>
  );
};
